import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import angular from 'angular';
import { IAddress, IAddressService } from '@typings/address';

export function useGetAddressesQuery(
  params: UseQueryOptions<{
    billingAddresses: IAddress[];
    shippingAddresses: IAddress[];
    reusableShippingAddresses: IAddress[];
  }>
) {
  return useQuery({
    queryKey: ['addresses'],
    queryFn: async () => {
      const service: IAddressService = angular
        .element(document.body)
        .injector()
        .get('AddressService');
      const addresses = await service.getAddresses().then((res) => res);
      return addresses;
    },
    ...params,
  });
}
