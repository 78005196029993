import ReactRootProviders from '@app/contexts/providers/ReactRootProviders';
import { UserSessionProvider } from '@app/contexts/providers/UserSessionProvider';
import { CompanyGatewayProvider } from '@app/pages/company/contexts/CompanyGatewayProvider';
import UpsDapModal from '@app/pages/couriers/components/UpsDapModal';
import { UpsDapModalProps } from '@app/pages/couriers/components/UpsDapModal/types';
import React, { ReactElement } from 'react';
import { react2angular } from 'react2angular';

export function WrappedUpsDapModal(props: UpsDapModalProps): ReactElement {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <CompanyGatewayProvider>
          <UpsDapModal {...props} />
        </CompanyGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularUpsDapModal = react2angular(WrappedUpsDapModal, [
  'isOpen',
  'onClose',
  'addressId',
  'countries',
  'onRegisterSuccess',
]);
