import { useCurrentSubscriptionQuery } from '@app/hooks/queries/useGetCurrentSubscriptionQuery';
import { useUserSession } from '@/providers//UserSessionProvider';

const goldenPlanSlug = 'golden';

export const useTrialExpiryBanner = () => {
  const { data: currentSubscription } = useCurrentSubscriptionQuery();

  const currentSubscriptionPeriod = currentSubscription?.period;
  const planVersion = currentSubscription?.plan.slug;
  const remainingDaysBeforeTrialEnd = currentSubscription?.freeTrialDaysLeft;

  const userSession = useUserSession();
  const { user, features } = userSession;

  const showTrialExpiryBanner = features.canUseTeamRightsAndPermission
    ? user.hasUserRole('subscription_and_billing') &&
      currentSubscriptionPeriod === 'TRIAL' &&
      planVersion?.toLowerCase() !== goldenPlanSlug
    : currentSubscriptionPeriod === 'TRIAL' && planVersion?.toLowerCase() !== goldenPlanSlug;

  return {
    showTrialExpiryBanner,
    remainingDaysBeforeTrialEnd,
    planVersion,
  };
};
