import { queryClient } from '@client/src/global/context/QueryClientProvider';
import { GET_CHECKOUT_PREPARE_QUERY_KEY } from '@/hooks/queries/useGetCheckoutPrepareQuery/types';
import { GET_USER_STATUS_CREDIT_BALANCE_KEY } from '@/hooks/queries/useGetUserStatusCreditBalanceQuery/types';
import { GET_CHECKOUT_DATA_QUERY_KEY } from '@/hooks/queries/useGetCheckoutDataQuery/types';

export const invalidateCheckoutPrepareQuery = () =>
  queryClient.invalidateQueries([GET_CHECKOUT_PREPARE_QUERY_KEY]);

export const invalidateCheckoutDataQuery = () =>
  queryClient.invalidateQueries([GET_CHECKOUT_DATA_QUERY_KEY]);

export const invalidateUserStatusCreditBalanceQuery = () =>
  queryClient.invalidateQueries([GET_USER_STATUS_CREDIT_BALANCE_KEY]);
