import React, { lazy, Suspense } from 'react';
import { react2angular } from 'react2angular';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import { UserSessionProvider } from '@client/src/global/context/UserSessionProvider';
import { SubscriptionServiceProvider } from '@client/src/global/context/SubscriptionProvider';
import { Pulse } from 'easyship-components';

const PrintingComponent = lazy(() => import('@/pages/settings/printing'));

function WrappedComponent() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <SubscriptionServiceProvider>
          <Suspense fallback={<Pulse show />}>
            <PrintingComponent />
          </Suspense>
        </SubscriptionServiceProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularSettingsPrinting = react2angular(WrappedComponent);
