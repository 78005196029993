import ReactRootProviders from '@app/contexts/providers/ReactRootProviders';
import { UpsDapCard } from '@app/pages/courier/components/UpsDapCard';
import { UpsDapCardProps } from '@app/pages/courier/components/UpsDapCard/types';
import React, { ReactElement } from 'react';
import { react2angular } from 'react2angular';

export function WrappedUpsDapCard(props: UpsDapCardProps): ReactElement {
  return (
    <ReactRootProviders>
      <UpsDapCard {...props} />
    </ReactRootProviders>
  );
}

export const AngularUpsDapCard = react2angular(WrappedUpsDapCard, [
  'countryCode',
  'totalServices',
  'onActivateClicked',
]);
