import angular from 'angular';
import 'angular-cookies';
import '@uirouter/angularjs';
import 'angular-translate';
import 'angular-translate-loader-static-files';
import 'messageformat';
import 'angular-translate-interpolation-messageformat';

import '@client/core/components';
import '@client/core/filters';
import '@client/core/config';
import helperService from '@client/core/services/helper';
// import '@client/src/global/index';
// import '@client/src/manage-shipments';
// // NOTE: The @client/app imports below need to be manually syncronized with the list in /client/app.index.js
// // TO DO: Can we generate a similar list in tools/generate-app-index.js ?
// import '@client/app/factories/Auth/Auth.service';
// import '@client/app/factories/Session/Session.service';
// import '@client/app/factories/Invitee/Invitee.service';
// import '@client/app/factories/WalkthroughData/WalkthroughData.factory';
// import '@client/app/factories/Batch/Batch.service';
// import '@client/app/factories/Courier/Courier.service';
// import '@client/app/components/components.index';
// import '@client/app/components/address-forms/address-form-au/_address-form-au';
// import '@client/app/components/address-forms/address-form-au/address-form-au.component';
// import '@client/app/components/address-forms/address-form-au/address-form-au.controller';
// import '@client/app/components/address-forms/address-form-ca/_address-form-ca';
// import '@client/app/components/address-forms/address-form-ca/address-form-ca.component';
// import '@client/app/components/address-forms/address-form-ca/address-form-ca.controller';
// import '@client/app/components/address-forms/address-form-contact-info/_address-form-contact-info';
// import '@client/app/components/address-forms/address-form-contact-info/address-form-contact-info.component';
// import '@client/app/components/address-forms/address-form-contact-info/address-form-contact-info.controller';
// import '@client/app/components/address-forms/address-form-default/_address-form-default';
// import '@client/app/components/address-forms/address-form-default/address-form-default.component';
// import '@client/app/components/address-forms/address-form-default/address-form-default.controller';
// import '@client/app/components/address-forms/address-form-gb/_address-form-gb';
// import '@client/app/components/address-forms/address-form-gb/address-form-gb.component';
// import '@client/app/components/address-forms/address-form-gb/address-form-gb.controller';
// import '@client/app/components/address-forms/address-form-hk/_address-form-hk';
// import '@client/app/components/address-forms/address-form-hk/address-form-hk.component';
// import '@client/app/components/address-forms/address-form-hk/address-form-hk.controller';
// import '@client/app/components/address-forms/address-form-nl/_address-form-nl';
// import '@client/app/components/address-forms/address-form-nl/address-form-nl.component';
// import '@client/app/components/address-forms/address-form-nl/address-form-nl.controller';
// import '@client/app/components/address-forms/address-form-sg/_address-form-sg';
// import '@client/app/components/address-forms/address-form-sg/address-form-sg.component';
// import '@client/app/components/address-forms/address-form-sg/address-form-sg.controller';
// import '@client/app/components/address-forms/address-form-us-addressy/_address-form-us-addressy';
// import '@client/app/components/address-forms/address-form-us-addressy/address-form-us-addressy.component';
// import '@client/app/components/address-forms/address-form-us-addressy/address-form-us-addressy.controller';
// import '@client/app/components/address-forms/address-form-us-backup/_address-form-us-backup';
// import '@client/app/components/address-forms/address-form-us-backup/address-form-us-backup.component';
// import '@client/app/components/address-forms/address-form-us-backup/address-form-us-backup.controller';
// import '@client/app/components/address-forms-container/_address-forms-container';
// import '@client/app/components/address-forms-container/address-forms-container.component';
// import '@client/app/components/address-forms-container/address-forms-container.controller';
// import '@client/app/components/fix-shipment-modal-body/_fix-shipment-modal-body';
// import '@client/app/components/fix-shipment-modal-body/cancel-shipment/_cancel-shipment';
// import '@client/app/components/fix-shipment-modal-body/cancel-shipment/cancel-shipment.component';
// import '@client/app/components/fix-shipment-modal-body/edit-shipment/_edit-shipment';
// import '@client/app/components/fix-shipment-modal-body/edit-shipment/edit-shipment.component';
// import '@client/app/components/fix-shipment-modal-body/fail-monkey/_fail-monkey';
// import '@client/app/components/fix-shipment-modal-body/fail-monkey/fail-monkey.component';
// import '@client/app/components/fix-shipment-modal-body/fix-shipment-modal-body.component';
// import '@client/app/components/fix-shipment-modal-body/pay-shipment/_pay-shipment';
// import '@client/app/components/fix-shipment-modal-body/pay-shipment/pay-shipment.component';
// import '@client/app/components/fix-shipment-modal-body/report-shipment/_report-shipment';
// import '@client/app/components/fix-shipment-modal-body/report-shipment/report-shipment.component';
// import '@client/app/components/fix-shipment-modal-body/success-monkey/_success-monkey';
// import '@client/app/components/fix-shipment-modal-body/success-monkey/success-monkey.component';
// import '@client/app/components/handover-options-list/handover-options-list.component';
// import '@client/app/components/handover-options-list/handover-options-list.controller';
// import '@client/app/components/input-error-message/input-error-message.component';
// import '@client/app/services/Action/Action.service';
// import '@client/app/services/AddressService/AddressService.service';
// import '@client/app/services/AddressyService/AddressyService.service';
// import '@client/app/services/BatchService/BatchService.service';
// import '@client/app/services/CompanyService/CompanyService.service';
// import '@client/app/services/CountryService/CountryService.service';
// import '@client/app/services/CourierService/CourierService.service';
// import '@client/app/services/EndpointService/EndpointService.service';
// import '@client/app/services/ItemCategoryService/ItemCategoryService.service';
// import '@client/app/services/MessagingService/MessagingService.service';
// import '@client/app/services/Metadata/Metadata.service';
// import '@client/app/services/PlatformService/PlatformService.service';
// import '@client/app/services/Platforms/ChanneladvisorService.service';
// import '@client/app/services/Platforms/EbayService.service';
// import '@client/app/services/Platforms/GrouponService.service';
// import '@client/app/services/Platforms/LazadaService.service';
// import '@client/app/services/Platforms/MatahariMallService.service';
// import '@client/app/services/Platforms/OnBuyService.service';
// import '@client/app/services/Platforms/ShopifyService.service';
// import '@client/app/services/Platforms/SquareService.service';
// import '@client/app/services/Platforms/TradeGeckoService.service';
// import '@client/app/services/Platforms/WoocommerceService.service';
// import '@client/app/services/PostalCodeService/PostalCodeService.service';
// import '@client/app/services/Rule/Rule.service';
// import '@client/app/services/Shipment-modals/fix-shipment/_fix-shipment';
// import '@client/app/services/Shipment-modals/fix-shipment/fix-shipment.controller';
// import '@client/app/services/Shipment-modals/fix-shipment/fix-shipment.service';
// import '@client/app/services/Shipment-modals/show-shipment/_show-shipment';
// import '@client/app/services/Shipment-modals/show-shipment/show-shipment.controller';
// import '@client/app/services/Shipment-modals/show-shipment/show-shipment.service';
// import '@client/app/services/ShipmentAction/ShipmentAction.service';
// import '@client/app/services/ShipmentCache/ShipmentCache.service';
// import '@client/app/services/ShipmentHelpers/ShipmentHelpers.service';
// import '@client/app/services/ShipmentList/ShipmentList.service';
// import '@client/app/services/StoreService/StoreService.service';
// import '@client/app/services/WalkthroughService/WalkthroughService.service';
// import '@client/app/services/WalkthroughTooltipService/WalkthroughTooltipService.service';
// import '@client/app/services/WelcomeService/WelcomeService.service';
// import '@client/app/services/analytics-details-modal/_analytics-details-modal';
// import '@client/app/services/analytics-details-modal/analytics-details-modal.controller';
// import '@client/app/services/analytics-details-modal/analytics-details-modal.service';
// import '@client/app/services/checkoutService/checkoutService.service';
// import '@client/app/services/create-new-address-modal/_create-new-address-modal';
// import '@client/app/services/create-new-address-modal/create-new-address-modal.controller';
// import '@client/app/services/create-new-address-modal/create-new-address-modal.service';
// import '@client/app/services/delete-store-modal/_delete-store-modal';
// import '@client/app/services/delete-store-modal/delete-store-modal.controller';
// import '@client/app/services/delete-store-modal/delete-store-modal.service';
// import '@client/app/services/incart-modals/incart-installation-modal/_incart-installation-modal';
// import '@client/app/services/incart-modals/incart-installation-modal/incart-installation-modal.controller';
// import '@client/app/services/incart-modals/incart-installation-modal/incart-installation-modal.service';
// import '@client/app/services/incart-modals/incart-installation-success/incart-installation-success.controller';
// import '@client/app/services/incart-modals/incart-installation-success/incart-installation-success.service';
// import '@client/app/services/incart-modals/token-installation-modal/_token-installation-modal';
// import '@client/app/services/incart-modals/token-installation-modal/token-installation-modal.controller';
// import '@client/app/services/incart-modals/token-installation-modal/token-installation-modal.service';
// import '@client/app/services/order-modals/edit-courier/_edit-courier';
// import '@client/app/services/order-modals/edit-courier/edit-courier.controller';
// import '@client/app/services/order-modals/edit-courier/edit-courier.service';
// import '@client/app/services/orientation-modal/_orientation-modal';
// import '@client/app/services/orientation-modal/orientation-modal.controller';
// import '@client/app/services/orientation-modal/orientation-modal.service';
// import '@client/app/services/platform-connect-modals/_platform-connect-modal';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/amazon-connect-form/amazon-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/api-connect-form/api-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/bigcommerce-connect-form/bigcommerce-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/brightpearl-connect-form/brightpearl-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/buy-on-google-connect-form/buy-on-google-connect-form.controller.js';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/cashier-connect-form/cashier-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/channeladvisor-connect-form/channeladvisor-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/dear-inventory-connect-form/dear-inventory-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/ebay-connect-form/ebay-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/ecwid-connect-form/ecwid-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/etsy-connect-form/etsy-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/groupon-connect-form/groupon-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/lazada-connect-form/lazada-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/linnworks-connect-form/linnworks-connect-form.controller.js';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/magento-connect-form/magento-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/matahari-mall-connect-form/matahari-mall-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/onbuy-connect-form/onbuy-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/sellbrite-connect-form/sellbrite-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/seller-center-connect-form/seller-center-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/shopify-connect-form/shopify-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/shopify-plus-connect-form/shopify-plus-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/shopmatic-connect-form/shopmatic-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/shoptiq-connect-form/shoptiq-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/square-connect-form/square-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/squarespace-connect-form/squarespace-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/tradegecko-connect-form/tradegecko-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/weebly-connect-form/weebly-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/wix-connect-form/wix-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/woocommerce-connect-form/woocommerce-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/zapier-connect-form/zapier-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-forms/zoho-inventory-connect-form/zoho-inventory-connect-form.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-modal.controller';
// import '@client/app/services/platform-connect-modals/platform-connect-modal.service';
// import '@client/app/services/services.index';
// import '@client/app/services/uibRoutingOptions/uibRoutingOptions.service';
// import '@client/app/services/walkthrough-modal/_walkthrough-modal';
// import '@client/app/services/walkthrough-modal/walkthrough-modal.controller';
// import '@client/app/services/walkthrough-modal/walkthrough-modal.service';
import './country-selector';
import './shipment-cells';
import './rate-tooltip';
import './select-address';
import '../services/payment-intent';
import '../services/check-residential/index.ts';

import subscriptionService from '@client/src/global/services/subscription';

import { AngularSettingsPrinting } from '@client/core/components/wrappers/settings/Printing/AngularPrinting';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { AngularLearnMore } from './learn-more';
import { CreditCardInputComponent } from './payments/credit-card-input/credit-card-input.component';
import { CreditCardSelectComponent } from './payments/credit-card-select/credit-card-select.component';
import { PaymentFormComponent } from './payments/payment-form/payment-form.component';
import { PaymentFormService } from './payments/payment-form/payment-form.service';
import { StripeService } from './payments/stripe/stripe.service';
import { ItemRowComponent } from './item-row/item-row.component';
import { ItemRowColumnComponent } from './item-row/item-row-column/item-row-column.component';
import { ItemRowLabelComponent } from './item-row/item-row-label/item-row-label.component';
import { ItemRowHeaderComponent } from './item-row/item-row-header/item-row-header.component';
import { TrackingRatingComponent } from './tracking-rating/tracking-rating.component';
import { ReceiverFormComponent } from './receiver-form/receiver-form.component';
import { SenderFormComponent } from './sender-form/sender-form.component.ts';
import { LuxotticaCustomFormComponent } from './luxottica-custom-form/luxottica-custom-form.component';
import { LuxotticaCustomSearchComponent } from './luxottica-custom-search/luxottica-custom-search.component';
import { AddressyToggleComponent } from './addressy-toggle/addressy-toggle.component';
import { AngularUploader } from './uploader';

import esSearchBar from './search-bar';
import { AngularTagSearch } from './tag-search';
import esDimensions from './dimensions';
import esPlatformLogo from './platform-logo';
import esCourierLogoAndName from './CourierLogoAndNameComponent';
import esPaymentMethodIcon from './payment-method-icon';
import memberListCard from './member-list-card';
import esDefaultDimensionsInput from './default-dimensions-input';
import esDefaultWeightInput from './default-weight-input';
import notificationCentreComponents from '../components/notification-centre';
import esStateSelector from './state-selector';
import esSkuNameSelectGroup from './sku-name-select-group';

angular
  .module('app.global.dashboard-component-library', [
    'ngCookies',
    'ui.router',
    'pascalprecht.translate',
    'core.components',
    'core.filters',
    'core.config',
    helperService,
    'app.global.helper',
    'app.factory.Auth',
    'app.global.report',
    'app.service.ShipmentCache',
    'app.service.ShipmentList',
    'app.service.EndpointService',
    'app.factory.UserSession',
    'app.service.CountryService',
    'app.service.PostalCodeService',
    'app.factory.Invitee',
    'app.global.dashboard-component-library.country-selector',
    'app.global.shipment-cells',
    'app.global.dashboard-component-library.rate-tooltip',
    'app.global.payment-intent',
    'app.global.check-residential',
    'app.service.checkoutService',
    'app.global.payment-source',
    esSearchBar,
    esDimensions,
    esPlatformLogo,
    esCourierLogoAndName,
    esDefaultDimensionsInput,
    esDefaultWeightInput,
    esPaymentMethodIcon,
    memberListCard,
    subscriptionService,
    notificationCentreComponents,
    'core.components.select-address',
    esStateSelector,
    esSkuNameSelectGroup,
  ])
  .component('esStarRating', StarRatingComponent)
  .component('esLearnMore', AngularLearnMore)
  .component('easyshipCreditCardInput', CreditCardInputComponent)
  .component('easyshipCreditCardSelect', CreditCardSelectComponent)
  .component('easyshipPaymentForm', PaymentFormComponent)
  .service('StripeService', StripeService)
  .service('PaymentFormService', PaymentFormService)
  .component('esItemRow', ItemRowComponent)
  .component('esItemRowColumn', ItemRowColumnComponent)
  .component('esItemRowLabel', ItemRowLabelComponent)
  .component('esItemRowHeader', ItemRowHeaderComponent)
  .component('esTrackingRating', TrackingRatingComponent)
  .component('receiverForm', ReceiverFormComponent)
  .component('esSenderForm', SenderFormComponent)
  .component('luxotticaCustomForm', LuxotticaCustomFormComponent)
  .component('luxotticaCustomSearch', LuxotticaCustomSearchComponent)
  .component('esAddressyToggle', AddressyToggleComponent)
  .component('esTagSearch', AngularTagSearch)
  .component('esUploader', AngularUploader)
  .component('esToolsPrinting', AngularSettingsPrinting);
