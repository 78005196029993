import Cookies from 'universal-cookie';
/* eslint-disable no-param-reassign */
import { toastError } from '@client/core/components/react/Toastify';

/**
 * @deprecated since core implementation, if you need to fix or add here then it's ideal to put it on
 *             AuthService: 'client/core/services/auth'
 */
(function () {
  Auth.$inject = [
    '$rootScope',
    '$http',
    '$translate',
    '$window',
    '$q',
    '$state',
    'AUTH_EVENTS',
    'API',
    'User',
    'Invitee',
    'UserSession',
    'GoogleAnalytics',
    'Session',
  ];

  function Auth(
    $rootScope,
    $http,
    $translate,
    $window,
    $q,
    $state,
    AUTH_EVENTS,
    API,
    User,
    Invitee,
    UserSession,
    GoogleAnalytics,
    Session
  ) {
    const cookies = new Cookies();

    const _isAdminUser = function (user) {
      return user && user.role && user.role.scope !== 'company';
    };

    const _removeCredentials = function () {
      const cookieDomain = window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';

      cookies.remove(API.credentials, {
        secure: true,
        sameSite: 'none',
        partitioned: true,
        domain: cookieDomain,
        path: '/',
      });
    };

    const _adminFailedLoginCallback = function () {
      _removeCredentials();

      $rootScope.$broadcast(AUTH_EVENTS.loginFailed, {
        response: {
          data: {
            status: 'Sorry, something went wrong. Please contact the system administrator.',
          },
        },
      });
    };

    return {
      getCredentials() {
        return cookies.get(API.credentials);
      },

      setCredentials(token) {
        const self = this;
        if (self.getCredentials()) _removeCredentials();

        const cookieDomain =
          window.location.hostname === 'localhost' ? 'localhost' : '.easyship.com';

        cookies.set(API.credentials, token, {
          secure: true,
          sameSite: 'none',
          partitioned: true,
          domain: cookieDomain,
          path: '/',
        });
      },

      saveCookies(data) {
        const self = this;
        // Check if a User is an admin (@easyship.com or belonging to Easyship)
        // Allow only for the sso state (used to login to zendesk)
        if (
          _isAdminUser(data.user) &&
          !$state.is('home.auth.sso') &&
          !$state.is('home.auth.verify')
        ) {
          _adminFailedLoginCallback();
        } else {
          self.setCredentials(data.session.session_token);
          cookies.set('ES_USED_DASHBOARD', true, { domain: 'easyship.com' });
        }
      },

      createLocalSession(data) {
        const self = this;
        if (_isAdminUser(data.user)) {
          _adminFailedLoginCallback();
          return;
        }

        // save the response into $cookies
        self.saveCookies(data);

        // Create session
        UserSession.destroy();
        UserSession.create(data);

        // Dispatch event to all services
        if (data.company) {
          $rootScope.$broadcast(AUTH_EVENTS.fullLoginSuccess);
        } else {
          $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);
        }
      },

      /**
       * Authenticate user and save token
       *
       * @param userData
       */
      login(userData, sessionData, recaptchaResponse) {
        const self = this;

        return $q(function (resolve, reject) {
          if (sessionData) {
            self.createLocalSession(sessionData);
          } else {
            $http
              .post(
                `${API.endpoint}/sessions`,
                { user: userData, token: recaptchaResponse },
                {
                  headers: { 'recaptcha-token': recaptchaResponse },
                  ignoreAuthModule: true,
                }
              )
              .then(function (response) {
                self.createLocalSession(response.data);
                resolve();
              })
              .catch(function (response) {
                // The login failed, we dispatch the error event
                $rootScope.$broadcast(AUTH_EVENTS.loginFailed, {
                  response,
                });
                reject(response.data.status);
              });
          }
        });
      },

      signup(payload, recaptchaToken) {
        const self = this;
        return $q(function (resolve, reject) {
          $http
            .post(`${API.endpoint}/users/signup`, payload, {
              headers: { 'recaptcha-token': recaptchaToken },
              ignoreAuthModule: true,
            })
            .then(function (response) {
              self.createLocalSession(response.data);
              resolve(response.data);
            })
            .catch(function (response) {
              reject(response.data.status);
            });
        });
      },

      logInAs(jwt) {
        const self = this;

        return $http.post(`${API.endpoint}/sessions/log_in_as`, { jwt }).then(function (response) {
          if (
            !response ||
            !response.data ||
            !response.data.session ||
            !response.data.session.session_token
          ) {
            throw new Error('API did not return a session token');
          }

          self.createLocalSession(response.data);
        });
      },

      /**
       * To be removed once the new enterprise login (redirectLogIn) is fully implemented
       */

      enterpriseLogIn(jwt) {
        const self = this;

        return $q(function (resolve, reject) {
          $http
            .post(`${API.endpoint}/sessions/enterprise_log_in`, { jwt })
            .then(function (response) {
              if (!response?.data?.session?.session_token) {
                reject();
              }

              self.createLocalSession(response.data);

              resolve(response.data.redirect_url);
            })
            .catch(function (response) {
              reject(response.data.status);
            });
        });
      },

      /**
       * New version of enterprise login because we will offer this login type to other users
       */

      redirectLogIn(jwt) {
        const self = this;

        return $q(function (resolve, reject) {
          $http
            .post(`${API.endpoint}/sessions/redirect_log_in`, { jwt })
            .then(function (response) {
              if (!response?.data?.session?.session_token) {
                reject();
              }

              self.createLocalSession(response.data);

              resolve(response.data.redirect_url);
            })
            .catch(function (response) {
              reject(response.data.status);
            });
        });
      },

      /**
       * Delete access token and user info
       */
      logout() {
        const self = this;
        const credentials = self.getCredentials();

        if (!credentials) return;

        Session.delete({ id: credentials })
          .$promise.then(function () {
            _removeCredentials();

            delete $http.defaults.headers.common.Authorization;

            UserSession.destroy();

            GoogleAnalytics.createEvent({
              loggedOut: true,
            });

            $rootScope.$broadcast(AUTH_EVENTS.logoutSuccess);
          })
          .catch(function () {
            toastError($translate.instant('auth.logout-error'));
          });
      },

      /**
       * The user has a session saved in the cookies, so we try to get his session back.
       */
      retrieveSession(shieldId) {
        const self = this;

        return $q(function (resolve, reject) {
          const credentials = self.getCredentials();

          if (!credentials) {
            reject({ noCredentials: true });
          }

          /**
           * BC-Break fix for the issue #180 about old version of localStorage stored data
           *
           * @see https://github.com/easyship/easyship-dashboard/pull/180
           */
          if (typeof credentials === 'undefined') {
            self.logout();

            reject({ noCredentials: true });
          }

          self.ping(credentials, shieldId).then(resolve).catch(reject);
        });
      },

      /**
       * Check if the user credentials are correct
       */
      ping(credentials, shieldId) {
        const self = this;

        self.credentials = credentials;

        return $q(function (resolve, reject) {
          if (!self.credentials) {
            reject();
            return;
          }

          if (UserSession.company && UserSession.company.id) {
            resolve();
            return;
          }

          $http
            .get(`${API.endpoint}/users/get_current_user`, {
              headers: { 'X-Shield-Session-Id': shieldId },
            })
            .then(function (response) {
              if (_isAdminUser(response.data)) {
                _adminFailedLoginCallback();
                reject();
              }

              // save the new data into cookies
              self.createLocalSession(response.data);

              // Recreate the session with updated data
              UserSession.destroy();
              UserSession.create(response.data);
              // Socket.subscribe(response.user.id);

              // Dispatch event to all services
              $rootScope.$broadcast(AUTH_EVENTS.fullLoginSuccess);

              resolve();
            })
            .catch(function ({ status }) {
              // If we got 401 Unauthorized, so the token is invalid
              if (status === 401) {
                _removeCredentials();
              }

              $rootScope.$broadcast(AUTH_EVENTS.loginFailed, {
                response: 'Oops! Something went wrong trying to login',
              });

              reject();
            });
        });
      },

      validateUserSsoCredentials(credentials, client) {
        const self = this;

        self.credentials = credentials;

        return $q(function (resolve, reject) {
          if (!self.credentials) {
            reject();
            return;
          }

          if (UserSession.company && UserSession.company.id) {
            resolve();
            return;
          }

          $http
            .get(`${API.endpoint}/users/get_current_user?authorization_token=${credentials}`)
            .then(function (response) {
              if (_isAdminUser(response.data)) {
                _adminFailedLoginCallback();
                reject();
              }

              // save the new data into cookies
              self.createLocalSession(response.data);

              // Recreate the session with updated data
              UserSession.destroy();
              UserSession.create(response.data);
              // Socket.subscribe(response.user.id);

              // Save this value in local storage for logout redirection
              if (client === 'luxottica') {
                $window.localStorage.setItem('isLuxotticaSso', 'true');
              } else {
                $window.localStorage.removeItem('isLuxotticaSso');
              }

              // Dispatch event to all services
              $rootScope.$broadcast(AUTH_EVENTS.fullLoginSuccess);

              resolve();
            })
            .catch(function ({ status }) {
              // If we got 401 Unauthorized, so the token is invalid
              if (status === 401) {
                _removeCredentials();
              }

              $rootScope.$broadcast(AUTH_EVENTS.loginFailed, {
                response: 'Oops! Something went wrong trying to login',
              });

              reject();
            });
        });
      },

      /**
       * Confirm a new user email
       *
       * @param {String}   confirmationToken
       * @param {Function} successCallback
       * @param {Function} failCallback
       *
       * @returns {$promise|*|B.$promise}
       */
      confirmUser(confirmationToken, successCallback, failCallback) {
        const self = this;
        successCallback = successCallback || angular.noop;
        failCallback = failCallback || angular.noop;

        return User.confirm(
          { email_confirmation_token: confirmationToken },
          function (response) {
            // save the response into cookies
            self.createLocalSession(response);

            // Create session
            UserSession.create(response);

            // Dispatch event to all services
            $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);

            return successCallback(response);
          },
          function (err) {
            self.logout();
            return failCallback(err);
          }
        );
      },

      /**
       * Confirm a new user email
       *
       * @param {String}   confirmationToken
       * @param {Object}   userParams
       * @param {Function} successCallback
       * @param {Function} failCallback
       * @returns {$promise|*|B.$promise}
       */
      confirmInvitee(confirmationToken, userParams, successCallback, failCallback) {
        const self = this;
        successCallback = successCallback || angular.noop;
        failCallback = failCallback || angular.noop;

        return Invitee.confirm(
          { email_confirmation_token: confirmationToken, user: userParams },
          function (response) {
            // save the response into cookies
            self.createLocalSession(response);

            // Create session
            UserSession.create(response);

            // Dispatch event to all services
            $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);

            return successCallback(response);
          },
          function (err) {
            self.logout();
            return failCallback(err);
          }
        );
      },

      /**
       * Set new password (after reset)
       *
       * @param {String}   user
       * @param {String}   token
       * @param {Function} callback
       *
       * @returns {$promise|*|B.$promise}
       */
      setNewPassword(user, token, callback) {
        const self = this;
        const cb = callback || angular.noop;

        return User.setNewPassword(
          {},
          {
            user,
            reset_password_token: token,
          },
          function (response) {
            // save the response into cookies
            self.createLocalSession(response);

            // Create session
            UserSession.create(response);

            // Dispatch event to all services
            $rootScope.$broadcast(AUTH_EVENTS.loginSuccess);

            return cb(response);
          },
          function (err) {
            return cb(err);
          }
        ).$promise;
      },

      /**
       * Change password
       *
       * @param {String}   user
       *
       * @returns {$promise|*|B.$promise}
       */
      updatePassword(user) {
        const self = this;
        return User.updatePassword(
          {},
          {
            user: {
              email: UserSession.user.email,
              old_password: user.oldPassword,
              password: user.password,
              password_confirmation: user.password,
            },
          },
          function (response) {
            // save the response into cookies
            self.createLocalSession(response);

            // Create session
            UserSession.update('session_token', response.session.session_token);
          }
        ).$promise;
      },

      /**
       * Gets all available info on authenticated user
       *
       * @returns {Object}
       */
      getCurrentUser() {
        return UserSession;
      },

      /**
       * @returns {boolean}
       */
      isLoggedIn() {
        const self = this;
        return !!self.getCredentials();
      },
    };
  }

  angular
    .module('app.factory.Auth', [
      'ngCookies',
      'app.factory.Invitee',
      'app.global.user',
      'app.factory.UserSession',
      'app.factory.Session',
      'core.service.mixpanel',
      'ui.router',
    ])
    .factory('Auth', Auth);
})();
