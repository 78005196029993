import angular from 'angular';

import { AngularConnectCourierFormFedex } from './AngularConnectCourierFormFedex';

const MODULE_NAME = 'app.couriers.courier-create.connect-courier.form.fedex';

angular
  .module(MODULE_NAME, [])
  .component('esConnectCourierFormFedex', AngularConnectCourierFormFedex);

export default MODULE_NAME;
