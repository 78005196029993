import CompanyGateway from '@app/pages/company/gateways/api/CompanyGateway';
import FakeCompanyGateway from '@app/pages/company/gateways/fake/FakeCompanyGateway';
// eslint-disable-next-line no-restricted-imports
import ICompanyGateway from '@client/core/corelogic/ports/company.interface';
import React, { createContext, useContext, useMemo } from 'react';

export interface CompanyGatewayContextData {
  companyGateway: ICompanyGateway;
}

const CompanyGatewayContext = createContext<CompanyGatewayContextData | undefined>(undefined);

interface CompanyGatewayProviderProps {
  children: React.ReactNode;
}

function CompanyGatewayProvider({ children }: CompanyGatewayProviderProps) {
  const data: CompanyGatewayContextData = useMemo(
    () => ({
      companyGateway: new CompanyGateway(),
    }),
    []
  );

  return <CompanyGatewayContext.Provider value={data}>{children}</CompanyGatewayContext.Provider>;
}

interface FakeCompanyGatewayProviderProps {
  children: React.ReactNode;
  value?: Partial<CompanyGatewayContextData>;
}

function FakeCompanyGatewayProvider({ children, value }: FakeCompanyGatewayProviderProps) {
  const data: CompanyGatewayContextData = useMemo(
    () => ({
      companyGateway: new FakeCompanyGateway(),
      ...value,
    }),
    [value]
  );

  return <CompanyGatewayContext.Provider value={data}>{children}</CompanyGatewayContext.Provider>;
}

function useCompanyGateway() {
  const companyGatewayContext = useContext(CompanyGatewayContext);

  if (companyGatewayContext === undefined) {
    throw new Error('useCompanyGateway must be used within a CompanyGatewayProvider');
  }
  return companyGatewayContext;
}

export { CompanyGatewayProvider, FakeCompanyGatewayProvider, useCompanyGateway };
