import { useUserSession } from '@app/contexts/providers/UserSessionProvider';
import { useCompanyGateway } from '@app/pages/company/contexts/CompanyGatewayProvider';
import { RegisterCourierSubAccountPayload } from '@app/pages/company/types';
import { useMutation } from '@tanstack/react-query';

interface RegisterCourierSubAccountsMutationProps {
  payload: RegisterCourierSubAccountPayload;
}

export default function useRegisterCourierSubAccountsMutation() {
  const { companyGateway } = useCompanyGateway();
  const { company } = useUserSession();

  return useMutation({
    mutationFn: ({ payload }: RegisterCourierSubAccountsMutationProps) => {
      return companyGateway.registerCourierSubAccounts(company.id, payload);
    },
  });
}
