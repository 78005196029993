import angular from 'angular';

import { AngularTemplateBlankComponent } from './AngularTemplateBlankComponent';

const MODULE_NAME = 'app.template-blank-component';

angular
  .module(MODULE_NAME, [])
  .component('esTemplateBlankComponent', AngularTemplateBlankComponent);

export default MODULE_NAME;
