import { UserRightsService } from '@client/core/services/user-rights/user-right.service';

import { IAuthService } from 'typings/core/services/auth';
import { IUserSession } from 'typings/user-session';

import {
  initShield,
  getShieldSessionId,
} from '@client/src/initializers/auth-full-login-success/shield-loader/shield-loader';

stateChangeBefore.$inject = ['$transitions', 'Auth', 'UserRightsService', 'UserSession'];
function stateChangeBefore(
  $transitions: any,
  Auth: IAuthService,
  UserRightsService: UserRightsService,
  UserSession: IUserSession
) {
  $transitions.onBefore({}, (transition: any) => {
    const fromStateName = (transition.from()?.name ?? '') as string;
    const toStateName = (transition.to()?.name ?? '') as string;
    const toStateParams = transition.params('to');

    const isLoginTransition = fromStateName === 'home.auth.login' && toStateName === 'app.home';

    const isPublicPath =
      toStateName.startsWith('auth') ||
      toStateName === 'log-in-as' ||
      toStateName === 'enterprise-login' || // To be removed after the new enterprise login (redirect-login) is fully implemented
      toStateName === 'redirect-login' ||
      toStateName.startsWith('home') ||
      (fromStateName.startsWith('home') && !isLoginTransition);
    if (isPublicPath) {
      return true;
    }

    // If the user is not logged in, we don't need to check the permission
    const isLoggedIn = Auth.isLoggedIn();
    if (!isLoggedIn) {
      return transition.router.stateService.target('home.auth.login');
    }

    if (!getShieldSessionId()) {
      // We can get the UserSession only after we retrieve it
      return initShield()
        .then((shieldId: string) => shieldId)
        .catch((err) => console.error(err))
        .finally(() => {
          return Auth.retrieveSession(getShieldSessionId())
            .then(() => {
              // If the beta key is not active, we don't need to check the permission
              if (
                !UserSession.getCompanyDashboardSettings()?.beta_feature_team_rights_and_permissions
              ) {
                return true;
              }

              if (UserRightsService.canNavigateTo(toStateName, toStateParams)) {
                return true;
              }

              // If the user doesn't have the required permission, then the user will be redirected to the fallbackRedirectPage
              const { fallbackRedirectPage } = UserRightsService;
              return transition.router.stateService.target(fallbackRedirectPage);
            })
            .catch(() => {
              // if the user's session is not valid, then use normal logout flow
              return true;
            });
        });
    }
    return Auth.retrieveSession(getShieldSessionId())
      .then(() => {
        // If the beta key is not active, we don't need to check the permission
        if (!UserSession.getCompanyDashboardSettings()?.beta_feature_team_rights_and_permissions) {
          return true;
        }

        if (UserRightsService.canNavigateTo(toStateName, toStateParams)) {
          return true;
        }

        // If the user doesn't have the required permission, then the user will be redirected to the fallbackRedirectPage
        const { fallbackRedirectPage } = UserRightsService;
        return transition.router.stateService.target(fallbackRedirectPage);
      })
      .catch(() => {
        // if the user's session is not valid, then use normal logout flow
        return true;
      });
  });
}

export { stateChangeBefore };
