import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { react2angular } from 'react2angular';

import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import Box from '@material-ui/core/Box';
import COLORS from '@client/src/colors';
import Typography from '@material-ui/core/Typography';
import Button from '@client/core/components/react/Button';
import { useTrialExpiryBanner } from '@/hooks/general/useTrialExpiryBanner';
import { UserSessionProvider } from '../../context/UserSessionProvider';

export default function TrialExpiryBanner() {
  const { formatMessage } = useIntl();

  const { showTrialExpiryBanner, remainingDaysBeforeTrialEnd, planVersion } =
    useTrialExpiryBanner();

  useEffect(() => {
    if (showTrialExpiryBanner) {
      document.body.classList.add('show-trial-expiry');
    }
  }, [showTrialExpiryBanner]);

  if (!showTrialExpiryBanner) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: '8px 0 8px 210px',
        zIndex: 26,
        bgcolor: COLORS.tealNewLight,
      }}
    >
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        style={{ gap: '16px' }}
      >
        <Typography variant="body2" component="p" color="textPrimary">
          <strong>
            <FormattedMessage
              id="subscription.trial-countdown-banner.days-left"
              values={{
                day: remainingDaysBeforeTrialEnd,
                version: planVersion
                  ? formatMessage({ id: `freemium-pricing.category.${planVersion}` })
                  : '',
              }}
            />
          </strong>
          &nbsp;
          <FormattedMessage id="subscription.trial-countdown-banner.trial-end" />
        </Typography>
        <Button variant="contained" href="/account/subscription?open=compare">
          <FormattedMessage id="subscription.trial-countdown-banner.button" />
        </Button>
      </Box>
    </Box>
  );
}

export function WrappedTrialExpiryBanner() {
  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <TrialExpiryBanner />
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularTrialExpiryBanner = react2angular(WrappedTrialExpiryBanner, [], []);
