// eslint-disable-next-line no-restricted-imports
import ICompanyGateway from '@client/core/corelogic/ports/company.interface';
import angular from 'angular';
import { ICompanyService } from 'typings/company';
import { IUser } from 'typings/user-session';
import axios from 'axios';
import { RegisterCourierSubAccountPayload } from '@app/pages/company/types';
import { camelToSnakeCase } from '@app/utils/snakeCamelTransform';

// this is a copy from NgCompanyGateway in /core/adapters/company
// TODO: move the NgCompanyGateway there to use this gateway
export default class CompanyGateway implements ICompanyGateway {
  private companyService: ICompanyService;

  constructor() {
    this.companyService = angular
      .element(document.body)
      .injector()
      .get<ICompanyService>('CompanyService');
  }

  getCompanyUsers(): Promise<IUser[]> {
    return new Promise((resolve, reject) => {
      this.companyService
        .getCompanyUsers()
        .then((data: { users: IUser[] }) => resolve(data.users), reject);
    });
  }

  // need type any because it returns nothing
  registerCourierSubAccounts(
    companyId: string,
    payload: RegisterCourierSubAccountPayload
  ): Promise<any> {
    return axios.post(
      `${import.meta.env.VITE_APP_ENDPOINT}/companies/${companyId}/courier_sub_accounts`,
      camelToSnakeCase(payload)
    );
  }
}
