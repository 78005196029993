import angular from 'angular';
import { AngularConnectSuccessComponent } from './AngularConnectSuccessComponent';

const MODULE_NAME = 'app.global.lyoc-flows.lyoc-success-component';

angular
  .module(MODULE_NAME, [])
  .component('esConnectSuccessComponent', AngularConnectSuccessComponent);

export default MODULE_NAME;
