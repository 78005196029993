(function () {
  Company.$inject = ['$resource', 'API'];
  function Company($resource, API) {
    const endpointUrl = API.endpoint;
    return $resource(
      `${endpointUrl}/companies/:company_id/:controller/:action`,
      {
        company_id: '@_company_id',
      },
      {
        query: {
          method: 'GET',
          params: {
            controller: '',
          },
        },
        update: {
          method: 'PATCH',
          params: {
            controller: '',
          },
        },
        getCompanyUsers: {
          method: 'GET',
          params: {
            controller: 'users',
          },
        },
        getCurrentStatus: {
          method: 'GET',
          params: {
            controller: 'current_status',
          },
        },
        createInvitee: {
          method: 'POST',
          params: {
            controller: 'invitees',
          },
        },
        redeemVoucher: {
          method: 'POST',
          params: {
            controller: 'redeem_voucher',
          },
        },
        getAnalytics: {
          method: 'GET',
          params: {
            controller: 'analytics',
          },
        },
        checkIfUniqueName: {
          method: 'GET',
          params: {
            controller: 'check_name',
          },
        },
        refundCredits: {
          method: 'POST',
          params: {
            controller: 'refund_balance',
          },
        },
        getPackingSlipBrandingData: {
          method: 'GET',
          params: {
            controller: 'branding',
            include: 'packing_slip',
          },
        },
        getTrackingEmailBrandingData: {
          method: 'GET',
          params: {
            controller: 'branding',
            include: 'tracking_email',
          },
        },
        getTrackingPageBrandingData: {
          method: 'GET',
          params: {
            controller: 'branding',
            include: 'tracking_page',
          },
        },
        getCompanyActivity: {
          method: 'GET',
          params: {
            controller: 'company_activity',
          },
        },
        updateDashboardSettings: {
          method: 'PATCH',
          params: {
            controller: 'dashboard_settings',
          },
        },
        registerCourierSubAccounts: {
          method: 'POST',
          params: {
            controller: 'courier_sub_accounts',
          },
        },
        closeAccount: {
          method: 'POST',
          params: {
            controller: 'account_closure',
          },
        },
        submitChurnReasons: {
          method: 'POST',
          params: {
            controller: 'leaving_reasons',
          },
        },
        createIdentitiyVerificationSession: {
          method: 'POST',
          params: {
            controller: 'identity_verification',
            action: 'session',
          },
        },
      }
    );
  }

  CompanyV2.$inject = ['$resource', 'API'];
  function CompanyV2($resource, API) {
    const endpointUrl = API.baseEndpoint;
    return $resource(
      `${endpointUrl}/v2/companies/:company_id/:controller`,
      {
        company_id: '@_company_id',
      },
      {
        getAnalytics: {
          method: 'GET',
          params: {
            controller: 'analytics',
          },
        },
      }
    );
  }

  angular
    .module('app.factory.Company', [])
    .factory('Company', Company)
    .factory('CompanyV2', CompanyV2);
})();
