import angular from 'angular';
import uibModal from 'angular-ui-bootstrap/src/modal';

import subscriptionService from '@client/src/global/services/subscription';
import courierBlankComponent from './components/CourierBlankComponent/index.js';
import esCourierPerCountry from './components/AngularCourierPerCountryComponent/index.js';
import esCourierCreate from './courier-create';
import esCourierRedirect from './courier-redirect';
import upsReadyLyocForm from './components/UpsReadyLyocForm';
import connectSuccessComponent from './components/ConnectSuccessComponent';

import { CouriersComponent } from './couriers.component';
import { CourierCardComponent } from './courier-card/courier-card.component';
import { CourierDetails } from './courier-details/courier-details.service';
import { CourierDetailsMainComponent } from './courier-details/courier-details-main/courier-details-main.component';
import { UpdateCourierComponent } from './courier-details/update-courier/update-courier.component';
import { ServiceRatingComponent } from './courier-details/service-rating/service-rating.component';
import { DeleteCourierComponent } from './courier-details/delete-courier/delete-courier.component';
import { TermsAndConditionsComponent } from './courier-create/terms-and-conditions/terms-and-conditions.component';
import { AddEditDeleteFedexBrokerModalComponent } from './courier-details/add-edit-delete-fedex-broker-modal/add-edit-delete-fedex-broker-modal.component';
import { BrokerCardComponent } from './courier-details/broker-card/broker-card.component';
import { AngularUpsDapCard } from './components/AngularUpsDapCard';
import { AngularUpsDapModal } from './components/AngularUpsDapModal';

const MODULE_NAME = 'app.couriers';

angular
  .module(MODULE_NAME, [
    uibModal,
    'app.global.contact-sales',
    'core.service.mixpanel',
    'core.components',
    'app.global.courier-accounts',
    'app.factory.UserSession',
    'app.factory.Courier',
    'app.service.CourierService',
    'app.service.MessagingService',
    'ui.router',
    courierBlankComponent,
    subscriptionService,
    esCourierCreate,
    esCourierRedirect,
    esCourierPerCountry,
    upsReadyLyocForm,
    connectSuccessComponent,
  ])
  .component('couriers', CouriersComponent)
  .component('courierCard', CourierCardComponent)
  .service('CourierDetails', CourierDetails)
  .component('courierDetailsMain', CourierDetailsMainComponent)
  .component('updateCourier', UpdateCourierComponent)
  .component('serviceRating', ServiceRatingComponent)
  .component('deleteCourier', DeleteCourierComponent)
  .component('termsAndConditions', TermsAndConditionsComponent)
  .component('esAddEditDeleteFedexBrokerModal', AddEditDeleteFedexBrokerModalComponent)
  .component('esBrokerCard', BrokerCardComponent)
  .component('esUpsDapCard', AngularUpsDapCard)
  .component('esUpsDapModal', AngularUpsDapModal);

export default MODULE_NAME;
