/* eslint-disable import/no-extraneous-dependencies */
import { Shield } from 'shield-js-npm';

const SITE_ID = null; // import.meta.env.VITE_KEY_SHIELD_SITE_ID;

const shield = new Shield();
let shieldSessionId = null;

async function initShield() {
  return new Promise((resolve, reject) => {
    if (!SITE_ID) {
      reject();
      return;
    }

    const timer = setTimeout(() => {
      reject(new Error('Shield timeout error'));
    }, 3000);

    shield.init({ siteId: SITE_ID }).then(() => {
      shield
        .getDeviceIntelligence()
        .then((res) => {
          clearTimeout(timer);
          shieldSessionId = res.result.session_id;
          resolve(shieldSessionId);
        })
        .catch((err) => {
          clearTimeout(timer);
          reject(err);
          console.error(err);
        });
    });
  });
}

function getShieldSessionId() {
  return shieldSessionId;
}

export { initShield, getShieldSessionId };
