export const smartpostHubIDs = [
  {
    name: 'ALPA Allentown',
    id: '5185',
  },
  {
    name: 'ATGA Atlanta',
    id: '5303',
  },
  {
    name: 'BAMD Baltimore',
    id: '5213',
  },
  {
    name: 'CHNC Charlotte',
    id: '5281',
  },
  {
    name: 'COCA Chino',
    id: '5929',
  },
  {
    name: 'DLTX Dallas',
    id: '5751',
  },
  {
    name: 'DNCO Denver',
    id: '5802',
  },
  {
    name: 'DTMI Detroit',
    id: '5481',
  },
  {
    name: 'EDNJ Edison',
    id: '5087',
  },
  {
    name: 'GCOH Grove City',
    id: '5431',
  },
  {
    name: 'HOTX Houston',
    id: '5771',
  },
  {
    name: 'GPOH Groveport Ohio',
    id: '5436',
  },
  {
    name: 'LACA Los Angeles',
    id: '5902',
  },
  {
    name: 'ININ Indianapolis',
    id: '5465',
  },
  {
    name: 'KCKS Kansas City',
    id: '5648',
  },
  {
    name: 'MAWV Martinsburg',
    id: '5254',
  },
  {
    name: 'MAPA Macungie',
    id: '5183',
  },
  {
    name: 'METN Memphis',
    id: '5379',
  },
  {
    name: 'MPMN Minneapolis',
    id: '5552',
  },
  {
    name: 'NBWI New Berlin',
    id: '5531',
  },
  {
    name: 'NENY Newburgh',
    id: '5110',
  },
  {
    name: 'NENJ Newark',
    id: '5095',
  },
  {
    name: 'NOMA Northborough',
    id: '5015',
  },
  {
    name: 'ORFL Orlando',
    id: '5327',
  },
  {
    name: 'PHPA Philadelphia',
    id: '5194',
  },
  {
    name: 'PHAZ Phoenix',
    id: '5854',
  },
  {
    name: 'PTPA Pittsburgh',
    id: '5150',
  },
  {
    name: 'SACA Sacramento',
    id: '5958',
  },
  {
    name: 'SBNJ South Brunswick',
    id: '5097',
  },
  {
    name: 'SCPA Scranton',
    id: '5186',
  },
  {
    name: 'SCUT Salt Lake City',
    id: '5843',
  },
  {
    name: 'SEWA Seattle',
    id: '5983',
  },
  {
    name: 'STMO St. Louis',
    id: '5631',
  },
  {
    name: 'RENV Reno',
    id: '5893',
  },
  {
    name: 'TAFL Tampa',
    id: '5345',
  },
  {
    name: 'WHIL Wheeling',
    id: '5602',
  },
  {
    name: 'WICT Windsor',
    id: '5061',
  },
];

export const smartpostEndorsements = [
  {
    name: 'Address Correction',
    value: 'ADDRESS_CORRECTION',
  },
  {
    name: 'Carrier Leave If No Response',
    value: 'CARRIER_LEAVE_IF_NO_RESPONSE',
  },
  {
    name: 'Change Service',
    value: 'CHANGE_SERVICE',
  },
  {
    name: 'Forwarding Service',
    value: 'FORWARDING_SERVICE',
  },
  {
    name: 'Return Service',
    value: 'RETURN_SERVICE',
  },
  {
    name: 'None',
    value: 'NONE',
  },
];

export const upsDapLinkMap = {
  DE: {
    tac: 'https://www.ups.com/de/en/support/shipping-support/legal-terms-conditions.page',
    hod: 'https://www.ups.com/de/en/support/shipping-support/shipping-special-care-regulated-items/hazardous-materials-guide.page',
    pi: 'https://www.ups.com/de/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
  },
  GB: {
    tac: 'https://www.ups.com/assets/resources/webcontent/en_GB/terms_carriage_eur.pdf',
    ta: 'https://www.ups.com/assets/resources/webcontent/en_GB/UTA.pdf',
    pi: 'https://www.ups.com/gb/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
    ptac: 'https://www.ups.com/assets/resources/webcontent/en_GB/DAP-paperless-invoice-terms-conditions.pdf',
  },
  US: {
    tac: 'https://assets.easyship.com/app/courier-docs/ups-access-license-agreement.pdf',
    ta: 'https://www.ups.com/assets/resources/media/en_US/D15_US.pdf',
    pi: 'https://www.ups.com/us/en/help-center/shipping-support/prohibited-items.page',
  },
  CA: {
    tac: 'https://www.ups.com/assets/resources/webcontent/en_CA/terms_service_ca.pdf',
    ta: 'https://www.ups.com/assets/resources/webcontent/en_CA/UTA.pdf',
    pi: 'https://www.ups.com/ca/en/support/shipping-support/shipping-special-care-regulated-items/prohibited-items.page',
  },
};
