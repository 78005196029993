/* eslint-disable import/no-cycle */
import Link from '@client/core/components/react/Link';
import { Button } from 'easyship-components';
import { UpsLarge } from 'easyship-components/icons';
import React, { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { react2angular } from 'react2angular';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UpsOauthGatewayProvider } from '@client/src/couriers/providers/UpsOauthGatewayProvider';
import useCreateUpsCourierAccountMutation from '@client/src/couriers/hooks/mutations/useCreateUpsCourierAccount';
import { CreateUpsCourierAccountPayload } from '@client/src/couriers/models/payloads/ups-oauth-payload';
import { useUpsRedirectUrlQuery } from '@client/src/couriers/hooks/queries/useUpsRedirectUrlQuery';
import { toastError, toastSuccess } from '@client/core/components/react/Toastify';
import useDeleteCourierMutation from '@client/src/couriers/hooks/mutations/useDeleteCourierMutation';
import { DeleteCourierError } from 'typings/courier';
import {
  UserSessionProvider,
  useUserSession,
} from '@client/src/global/context/UserSessionProvider';
import ReactRootProviders from '@client/src/global/context/ReactRootProviders';
import UpsReadyInputFields from './UpsReadyInputFields/UpsReadyInputFields';
import { ICourierAccount } from '../../models/data/lyoc-courier-account';

interface IUpsReadyLyocFormProps {
  courierId: string;
  onBackClick: () => void;
  onDeleteSuccess: () => void;
  defaultValue: IUpsReadyFormValues;
  showBackButton: boolean;
  onCreateSuccess: () => void;
  originCountryId: number;
}

export interface IUpsReadyFormValues {
  accountName: string;
  accountNumber: string;
}

const defaultFormValues: IUpsReadyFormValues = {
  accountName: '',
  accountNumber: '',
};

function UpsReadyLyocForm({
  courierId,
  onBackClick,
  onDeleteSuccess,
  defaultValue,
  showBackButton = true,
  onCreateSuccess,
  originCountryId,
}: IUpsReadyLyocFormProps) {
  const queryClient = useQueryClient();

  const { formatMessage } = useIntl();
  const {
    company,
    user: { isConnectLYOCUserActionEnabled },
  } = useUserSession();
  const { handleSubmit } = useFormContext<IUpsReadyFormValues>();
  const [errorMessage, setErrorMessage] = useState('');

  const { mutateAsync: createUpsAccountMutateAsync } = useCreateUpsCourierAccountMutation();

  const { mutateAsync: deleteCourierMutateAsync, isLoading: isDeleting } =
    useDeleteCourierMutation();

  const [createdAccount, setCreatedAccount] = useState<ICourierAccount | undefined>(undefined);

  const { data, isFetching: isRedirecting } = useUpsRedirectUrlQuery({
    companyId: company.id,
    upsAccountId: createdAccount ? createdAccount.id : '',
    enabled: !!createdAccount?.id,
  });

  useEffect(() => {
    if (data) {
      window.location.href = data.authorizeUrl;
      onCreateSuccess();
      toastSuccess(formatMessage({ id: 'courier.lyoc-courier.ups-ready.oauth-redirecting' }));
      queryClient.removeQueries({ queryKey: ['upsRedirectUrl'] });
    }
  }, [data, formatMessage, onCreateSuccess, queryClient]);

  const createCourierAccount = async (form: IUpsReadyFormValues) => {
    const payload: CreateUpsCourierAccountPayload = {
      account: {
        nickname: form.accountName,
        account_number: form.accountNumber,
        origin_country_id: originCountryId,
      },
    };
    const response = await createUpsAccountMutateAsync({ companyId: company.id, payload });
    await setCreatedAccount(response);

    return response;
  };

  const processUserSubmission = async (form: IUpsReadyFormValues) => {
    const courierAccount = await createCourierAccount(form);

    localStorage.setItem(courierAccount.id, JSON.stringify(form));
  };

  const mutation = useMutation(processUserSubmission, {
    onError: (error: AxiosError<{ status: string }>) => {
      if (error.response?.data?.status) {
        setErrorMessage(error.response?.data?.status);
      } else {
        setErrorMessage(
          formatMessage({
            id: 'toast.default-error',
          })
        );
      }
    },
  });

  const onSubmit: SubmitHandler<IUpsReadyFormValues> = (form: IUpsReadyFormValues) => {
    mutation.mutateAsync(form);
  };

  const handleDeleteCourier = async () => {
    try {
      await deleteCourierMutateAsync(courierId);
      onDeleteSuccess();
    } catch (error: unknown) {
      const typedError = error as DeleteCourierError;
      const errorText =
        typedError.data && typedError.data.status
          ? typedError.data.status
          : formatMessage({ id: 'toast.default-error' });
      toastError(errorText);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <header className="flex items-start justify-between gap-3 p-5 pl-12 border-b border-b-sky-300">
        <div className="flex items-center w-full gap-3">
          <UpsLarge />

          <div className="text-xl break-words text-ink-900">
            <FormattedMessage id="courier.lyoc-courier.ups-ready.title" />
          </div>
        </div>
      </header>

      <main className="px-12 py-5 text-base text-ink-900">
        <p className="mb-5">
          <FormattedMessage id="courier.lyoc-courier.ups-ready.description-part-one" />
          <Link
            href="https://support.easyship.com/hc/en-us/articles/360039959232-Link-Your-UPS-Account"
            target="_blank"
          >
            <FormattedMessage id="courier.lyoc-courier.ups-ready.support-page" />
          </Link>
          <FormattedMessage id="courier.lyoc-courier.ups-ready.dot" />
        </p>

        <p className="m-0">
          <FormattedMessage id="courier.lyoc-courier.ups-ready.description-part-two" />
        </p>

        <div className="pt-4 mb-4 border-b border-b-sky-300" />

        <UpsReadyInputFields
          defaultValue={defaultValue}
          isError={mutation.isError}
          errorMessage={errorMessage}
        />

        <div className="mt-4 text-sm italic text-ink-500">
          <FormattedMessage id="courier.lyoc-courier.ups-ready.trademark" />
        </div>
      </main>

      <footer className="flex justify-between gap-3 px-12 py-5 border-t border-t-sky-300 pl-9">
        {showBackButton ? (
          <Button flat onClick={onBackClick}>
            <FormattedMessage id="courier.lyoc-courier.ups-ready.back" />
          </Button>
        ) : (
          <Button
            loading={isDeleting}
            disabled={isDeleting}
            className="px-5 disabled:!bg-sky-500"
            type="button"
            color="danger"
            onClick={handleDeleteCourier}
          >
            <FormattedMessage id="global.delete" />
          </Button>
        )}

        <Button
          loading={mutation.isLoading || isRedirecting}
          disabled={mutation.isLoading || isRedirecting || !isConnectLYOCUserActionEnabled()}
          color="primary"
          className="px-10 disabled:!bg-sky-500"
          type="submit"
        >
          <FormattedMessage id="courier.lyoc-courier.ups-ready.next" />
        </Button>
      </footer>
    </form>
  );
}

export function WrappedUpsReadyLyocForm(props: IUpsReadyLyocFormProps) {
  const methods = useForm<IUpsReadyFormValues>({
    defaultValues: defaultFormValues,
  });

  return (
    <ReactRootProviders>
      <UserSessionProvider>
        <UpsOauthGatewayProvider>
          <FormProvider {...methods}>
            <UpsReadyLyocForm {...props} />
          </FormProvider>
        </UpsOauthGatewayProvider>
      </UserSessionProvider>
    </ReactRootProviders>
  );
}

export const AngularUpsReadyLyocForm = react2angular(WrappedUpsReadyLyocForm, [
  'courierId',
  'onBackClick',
  'onDeleteSuccess',
  'defaultValue',
  'showBackButton',
  'onCreateSuccess',
  'originCountryId',
]);
