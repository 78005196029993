import { TransformToCamelCase } from '@/types/general';
import { ICompany, IUser } from '@typings/user-session';

export const GET_USER_SESSION_QUERY_KEY = 'GET_USER_SESSION_QUERY_KEY';

export type UserSession = TransformToCamelCase<{
  company?: ICompany;
  user?: IUser;
}>;

export type Company = NonNullable<UserSession['company']>;
