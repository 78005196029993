import { toastError } from '@client/core/components/react/Toastify';
import {
  invalidateCheckoutDataQuery,
  invalidateCheckoutPrepareQuery,
} from '@client/core/adapters/helpers/invalidateQueries';

(function () {
  CheckoutService.$inject = [
    '$q',
    '$window',
    '$translate',
    '$state',
    'HelperService',
    'Checkout',
    'UserSession',
    'CompanyService',
    'UserStatusService',
    'MixpanelService',
    'ReschedulePickup',
    'ShipmentListAdvancedService',
  ];

  /**
   * [CheckoutService]
   * - Holds all data and payload necessary to go through the checkout flow (single and multiple)
   * - Prepares and updates data for front end interactions during the checkout flow
   *
   * Full flow:
   * 1. Prepare (before entering checkout flow)
   * 2a. Checkout flow initialisation calculations
   * 2b. totalCost calculations (request pickup page)
   * 2c. Validations when exiting Request Pickup (going to order summary)
   * 3. Success (exiting checkout flow): payment + confirm
   *
   */
  function CheckoutService(
    $q,
    $window,
    $translate,
    $state,
    HelperService,
    Checkout,
    UserSession,
    CompanyService,
    UserStatusService,
    MixpanelService,
    ReschedulePickup,
    ShipmentListAdvancedService
  ) {
    const service = this;
    const INSURANCE_OBJECT_ID = 'insuranceCourierId';

    service.addonsSkipped = false;

    // -----------------------------------------------
    // 1. Prepare (before entering checkout flow)
    // -----------------------------------------------
    //
    // # PREPARE - req_body
    // {
    //   shipment_ids: [],
    //   single_shipment: true,         # empty if multiple
    //   exclude_shipment_ids: [],      # empty if single
    //   keyword: null,                 # empty if single
    //   destination_country_id: null,  # empty if single
    //   courier_id: null,              # empty if single
    //   platform_name: null,           # empty if single
    //   from_date: null,               # empty if single
    //   to_date: null,                 # empty if single
    //   limit: null,                   # empty if single
    // }
    //

    /**
     * [prepareMultipleOrders]
     * This methods is for now used as a shortcut:
     * - Call prepare for multiple shipments
     * - no need to build the params in the controller, they are built here using ShipmentList, ShipmentCache and EndpointService
     *
     * @return {Promise}
     */
    service.prepareMultipleOrders = function () {
      return $q(function (resolve, reject) {
        const payload = ShipmentListAdvancedService.shipmentCheckoutPreparePayload;

        service.prepareAndSaveMultipleOrdersWithPayload(payload, resolve, reject);
      });
    };

    service.prepareAndSaveMultipleOrdersWithPayload = function (payload, resolve, reject) {
      return service
        .prepareAndSave(
          {
            company_id: UserSession.getCompanyId(),
          },
          payload
        )
        .then(function (data) {
          sendMultipleSuccessMixpanelEvents(data.shipments);

          resolve(data);
        })
        .catch(function (err) {
          reject(err);
        });
    };

    /**
     * [prepareReschedulePickup]
     * @return {Promise}
     */
    service.prepareReschedulePickup = function (pickupId) {
      return $q(function (resolve, reject) {
        const payload = {
          company_type: UserSession.getCompanyType(),
          limit: 1,
          pickup_id: pickupId,
        };

        service.prepareAndSaveReschedulePickupWithPayload(payload, resolve, reject);
      });
    };

    service.prepareAndSaveReschedulePickupWithPayload = function (payload, resolve, reject) {
      return service
        .prepareAndSaveReschedulePickup(
          {
            company_id: UserSession.getCompanyId(),
          },
          payload
        )
        .then(function (data) {
          resolve(data);
        })
        .catch(function (err) {
          reject(err);
        });
    };

    service.singleReschedulePickup = function (shipmentId) {
      return service
        .prepareAndSaveReschedulePickup(
          {
            company_type: UserSession.getCompanyType(),
            company_id: UserSession.getCompanyId(),
            limit: 1,
          },
          {
            shipment_ids: [shipmentId],
          }
        )
        .then(function (data) {
          service.initCheckout(data, { isReschedulePickup: true });
          $state.go('app.reschedule-pickup.handover');
        })
        .catch(function () {
          toastError($translate.instant('toast.default-error'));
        });
    };

    /**
     * [sendMultipleSuccessMixpanelEvents]
     * Send one event per shipment processed
     *
     * @param  {Object} shipments: shipments processed
     */
    function sendMultipleSuccessMixpanelEvents(shipments) {
      if (!shipments) return;

      shipments.forEach(function (shipment, index) {
        const obj = {};

        obj[`shipment_no${index}_destination`] = shipment.destination_country_name;
        obj[`shipment_no${index}_courier`] = shipment.courier_name;
        obj[`shipment_no${index}_weight`] = shipment.total_actual_weight;
        obj[`shipment_no${index}_total_price`] = shipment.total_charge;

        MixpanelService.track('Multiple - Pick Up (Step 1) Success', obj);
      });
    }

    /**
     * [prepareAndSave]
     * Call the checkout#prepare endpoint (prepare and compute data to be ready for checkout in the BE)
     *
     * @param  {Object} apiParams: company_id, query params, single_shipment...
     * @param  {Object} payload: shipment_ids or exclude_shipment_ids
     *
     * @return {Promise}
     */
    service.prepareAndSave = function (apiParams, payload) {
      return $q(function (resolve, reject) {
        Checkout.prepare.save(
          apiParams,
          payload,
          function (data) {
            resolve(data);
          },
          function (err) {
            reject(err);
          }
        );
      });
    };

    /**
     * [prepareAndSaveReschedulePickup]
     *
     * @param  {Object} apiParams: company_id, query params, single_shipment...
     * @param  {Object} payload: shipment_ids or exclude_shipment_ids
     *
     * @return {Promise}
     */
    service.prepareAndSaveReschedulePickup = function (apiParams, payload) {
      return $q(function (resolve, reject) {
        ReschedulePickup.prepare(
          apiParams,
          payload,
          function (data) {
            resolve(data);
          },
          function (err) {
            reject(err);
          }
        );
      });
    };

    // -----------------------------------------------
    // 2a. Checkout flow initialisation calculations
    // -----------------------------------------------

    // Holds payload that will be sent at the end of the flow
    service.payload = {};

    // Holds a enhanced copy of the data given at the entry of the flow
    service.data = {};

    service.purePreparedCheckoutData = {};

    // Holds any errors in the request pickup page to manually validate the required fields
    service.errors = {
      residential: false,
      insurance: false,
      options: false,
      pickupInstruction: false,
    };

    /**
     * [recalculateTotalsToPay]
     * Manually recalculate totals to display adding the pickup fees
     * total_pay_at_easyship
     * total_pay_at_courier
     * displayableTotalToTopUp
     *
     */
    service.recalculateTotalsToPay = function () {
      // 1. Split pay at easyship and counter
      let courierTotalsPayToEasyship = 0;
      let courierTotalsPayToCourier = 0;

      service.data.origins.forEach(function (origin, originIndex) {
        service.payload.origins[originIndex].pickup_address_id = origin.pickup_address_id;
        service.payload.origins[originIndex].sender_address_id = origin.sender_address_id;

        origin.couriers.forEach(function (courier) {
          courier.applicableCosts = _sanitizeCosts(courier);

          if (courier.courier_payment_recipient === 'Easyship') {
            courierTotalsPayToEasyship += _calculateCourierTotalCost(courier.applicableCosts);
          } else {
            courierTotalsPayToCourier += _calculateCourierTotalCost(courier.applicableCosts);
          }
        });
      });

      // 2. Rebuild sums
      service.data.totals.total_pay_at_easyship = courierTotalsPayToEasyship;
      service.data.totals.total_pay_at_courier = courierTotalsPayToCourier;
      service.data.displayableTotalToTopUp = _calculateDisplayableTotalToTopUp();
    };

    service.skipAddOns = function () {
      // If the insurance card is hidden or not available
      // AND If the residential card is hidden or not available
      // AND If the handover is preselected and valid (if pickup -> have handover_id)
      return (
        _doesNotHaveResidentialCoverageToBeApplied() &&
        _doesNotHaveInsuranceToBeApplied() &&
        (service.doesNotHaveHandoverToChoose() || _hasAllHandoversSelected())
      );
    };

    function _doesNotHaveResidentialCoverageToBeApplied() {
      return !service.hasResidentialCoverageToBeApplied();
    }

    function _doesNotHaveInsuranceToBeApplied() {
      return !service.hasInsuranceToBeApplied();
    }

    service.doesNotHaveHandoverToChoose = function () {
      return service.data.origins.every(function (origin) {
        return origin.couriers.every(function (courier) {
          return courier.courier_handover_options.length === 0;
        });
      });
    };

    /**
     * [initCheckout]
     * Initialize starting data
     *
     * @param  {Object} pickupParams: data at the entry of the flow (given by the response of checkout#prepare)
     * @param  {Object} params: additional params (i.e. isSingleShipment)
     */
    service.initCheckout = function (pickupParams, params) {
      params = params || {};

      service.purePreparedCheckoutData = {
        origins: (pickupParams?.origins || []).map((d) => ({
          ...d,
          couriers: d.couriers.filter((c) => !c.isReturn && !c.isInsurance),
        })),
        totals: pickupParams?.totals,
        set_all_as_insured: pickupParams?.set_all_as_insured,
      };
      invalidateCheckoutPrepareQuery().then();

      const formattedData = _formatDataOnInit(pickupParams, params);

      // Ensure we clear data and payload when initializing
      service.data = {};
      service.payload = {};
      service.errors = {
        residential: false,
        insurance: false,
        options: false,
        pickupInstruction: false,
      };

      service.data = buildInitCheckoutData(formattedData);
      service.payload = buildInitCheckoutPayload(formattedData, params);
      service.recalculateTotalsToPay();

      invalidateCheckoutDataQuery().then();
      return true;
    };

    function _hasAllHandoversSelected() {
      const pickups = _.reduce(
        service.payload.origins,
        function (agg, origin) {
          return agg.concat(origin.pickups || []);
        },
        []
      );
      let hasMissignHandoverInfo = false;

      function _doesNotHaveAHandoverOptionId(pickup) {
        return !pickup.handover_option_id;
      }

      function _isAPickupWithoutDate(pickup) {
        return pickup.handoverOptionCategory === 'pickup' && !pickup.selected_date;
      }

      // Loop through the pickups and break if at least one does not have a handover id
      // or is a pickup without a date selected
      for (let index = 0; index < pickups.length; index++) {
        if (
          _doesNotHaveAHandoverOptionId(pickups[index]) ||
          _isAPickupWithoutDate(pickups[index])
        ) {
          hasMissignHandoverInfo = true;
          break;
        }
      }

      return !hasMissignHandoverInfo;
    }

    function _formatDataOnInit(data, params) {
      params = params || {};

      // No need to include the insurance for the reschedule pickup flow (only shipments are insured)
      if (!params.isReschedulePickup) {
        data = _addPrepaidReturnShipmentToCheckoutData(data);
        data = _addInsuranceToCheckoutData(data);
      }

      return data;
    }

    function _addPrepaidReturnShipmentToCheckoutData(data) {
      const prepaidData = data.return_couriers.reduce(
        function (accumulator, courier) {
          if (courier.payment_recipient === 'Easyship') {
            accumulator.shipments_count += courier.shipments_count;
            accumulator.shipping_cost += courier.shipping_cost;
          }

          return accumulator;
        },
        {
          shipments_count: 0,
          shipping_cost: 0,
        }
      );

      // Add a fake courier object to show the prepaid return shipment as an additional row in the summary, followed the same rules
      const returnAsCourier = {
        courier_id: 'prepaidReturnShipmentId',
        courier_display_name: $translate.instant(
          'checkout.summary.pay-now.prepaid-return-description',
          {
            count: prepaidData.shipments_count,
          },
          'messageformat'
        ),
        courier_admin_name: 'Prepaid',
        courier_logo_url: 'prepaid',
        courier_payment_recipient: 'Easyship',
        is_easyship_courier: true,
        shipment_ids: [], // NOTE: Checkout is failing without
        shipments_count: prepaidData.shipments_count,
        isReturn: true,
        courier_handover_options: [],
        totals: {
          shipping_cost: prepaidData.shipping_cost,
        },
      };

      data.origins.forEach(function (origin) {
        origin.couriers.push(returnAsCourier);
      });

      return data;
    }

    function _addInsuranceToCheckoutData(data) {
      // Add a fake courier object to show the insurance as an additional row in the summary to maintain the structure and keep the logic away from the components
      const insuranceAsCourier = {
        courier_id: INSURANCE_OBJECT_ID,
        courier_display_name: _buildInsuranceDescription(data.totals),
        courier_name: _buildInsuranceDescription(data.totals),
        courier_nickname: null,
        courier_admin_name: 'Insurance',
        courier_logo_url: 'insurance',
        courier_payment_recipient: 'Easyship',
        courier_tracking_rating: null,
        courier_is_hybrid: false,
        courier_remarks: null,
        is_easyship_courier: true,
        shipment_ids: [],
        shipments_count: null,
        available_handover_options: null,
        isInsurance: true,
        courier_handover_options: [],
        handover_handler_name: null,
        shipments_count_insured: data.totals.shipments_count_insured || 0,
        shipments_count_insured_not_charged: data.totals.shipments_count_insured_not_charged || 0,
        totals: {
          shipping_cost: 0,
          warehouse_handling_fee: 0,
          additional_services_surcharge: 0,
          residential_fee_applied: 0,
          oversized_surcharge: 0,
          insurance_fee: data.totals.total_insurance_fee,
          import_duty_charge: 0,
          import_tax_charge: 0,
          ddp_handling_fee: 0,
          pickup_fee: null,
        },
      };

      data.origins[0]?.couriers?.push(insuranceAsCourier);

      return data;
    }

    function _buildInsuranceDescription(totals, options) {
      totals = totals || {};
      options = options || {};

      let count = totals.shipments_count_insured - totals.shipments_count_insured_not_charged;

      if (options.insuranceAdded && options.setAllAsInsured) {
        count += totals.shipments_count_insured_not_applied;
      } else if (options.insuranceAdded && !options.setAllAsInsured) {
        count -= totals.shipments_count_insured_not_applied;
      }

      return count > 0
        ? `Insurance for ${count}${count === 1 ? ' shipment' : ' shipments'}`
        : 'Insurance';
    }

    /**
     * [buildData]
     * Copy and enhance main data necessary for the flow (given by the response of checkout#prepare)
     *
     * @param  {Object} pickupParams: data at the entry of the flow
     */
    function buildInitCheckoutData(data) {
      data.origins = calculateAllCourierTotals(data.origins);

      data.origins.forEach(function (origin) {
        origin.showPickupAddress = doesAnyCourierDoPickup(origin.couriers);
      });

      // Creates copy of total to top up to make recalculations easier
      data.baseTotalToTopUp = angular.copy(data.totals.total_to_top_up);

      return data;
    }

    /**
     * [doesAnyCourierDoPickup]
     * Use the supported_handover_options key in each courier to figure out if any solutions have pickup
     * in order to know if we show the pickup address in the request pickup step (for non-US users).
     *
     * @param  {[type]} couriers [description]
     * @return {[type]}          [description]
     */
    function doesAnyCourierDoPickup(couriers) {
      return !_.every(couriers, ['available_handover_options', 'dropoff']);
    }

    /**
     * [buildPayload]
     * Build payload object that will be sent at the end of the checkout flow
     *
     * @param  {Object} pickupParams: data at the entry of the flow (given by the response of checkout#prepare)
     * @param  {Object} params: additional params: isSingleShipment, isReschedulePickup
     * @return {Object} payload object (described at the top of this file)
     */
    function buildInitCheckoutPayload(pickupParams, params) {
      let payload = {
        is_payment_source_reusable: null,
        payment: null,
        origins: buildPickupsKey(pickupParams),
        set_all_as_insured: pickupParams.set_all_as_insured,
      };

      payload = _applyPayloadPresets(payload, params);
      return payload;
    }

    function _applyPayloadPresets(payload, params) {
      // Handover presets
      const createShipmentAdditionalAttributes = {
        single_shipment:
          params.isSingleShipment || $window.location.pathname.includes('/basic') || false,
      };

      // Creating a shipment requires more attributes than just reschduling a pickup
      if (!params.isReschedulePickup) {
        payload = _.assign(payload, createShipmentAdditionalAttributes);
      }

      // Insurance presets
      if (payload.set_all_as_insured || UserSession.hideInsuranceAddOn()) {
        if (UserSession.hideInsuranceAddOn()) payload.set_all_as_insured = false;

        service.updateInsuranceCost(payload.set_all_as_insured);
      }

      // Residential presets
      if (UserSession.hideResidentialAddOn()) {
        payload.set_all_as_residential = false;
      }

      return payload;
    }

    /**
     * [buildPickupsKey]
     * Loop through the couriers in the main data to build the pickups array necessary in the payload
     *
     * @param  {Object} pickupParams: data at the entry of the flow (given by the response of checkout#prepare)
     * @return {Array}  origins (described at the top of this file)
     */
    function buildPickupsKey(pickupParams) {
      return _.map(
        pickupParams.origins,
        function (origin, originIndex) {
          const pickups = _.reduce(
            origin.couriers,
            function (arr, courier, courierIndex) {
              if (courier.isInsurance || courier.isReturn) return arr;

              let pickup = {
                handover_option_id: null,
                time_slot_id: null,
                courier_id: courier.courier_id,
                shipment_ids: courier.shipment_ids,
                selected_date: null,
                handoverOptionCategory: null,
                address_id: courier.pickup_address && courier.pickup_address.id,
                availableHandoverOptions: courier.available_handover_options,
                pickupFee: null,
                courierAdminName: courier.courier_admin_name,
                pickupAddressZip: courier.pickup_address && courier.pickup_address.postal_code,
                additional_services: [],
              }; // for mixpanel analytics //for mixpanel analytics //for mixpanel analytics //for mixpanel analytics

              pickup = preselectHandoverOption(
                pickup,
                courier.courier_handover_options,
                courierIndex,
                originIndex,
                _getPreselectHandoverOptionsPreferences()
              );
              arr.push(pickup);
              service.addonsSkipped = service.skipAddOns();

              return arr;
            },
            []
          );

          return {
            pickup_address_id: origin.pickup_address_id,
            sender_address_id: origin.sender_address_id,
            pickups,
          };
        },
        []
      );
    }

    function _getPreselectHandoverOptionsPreferences() {
      return {
        preselectAnyHandover: UserSession.hideHandoverOptionsAddOn(),
        preferDropoff: UserSession.hideHandoverOptionsAddOn(),
      };
    }

    /**
     * [preselectHandoverOption]
     *
     * If there is only one handover option in the array and is a pickup, return the desired key
     * Also recalculate the courier total if the only pickup is a paid one (has a pickup_fee)
     * @param  {Object} pickup: pre-built pickup object
     * @param  {Array} courierHandoverOptions: array if handover options in a courier
     * @param  {Integer} courierIndex: index of the courier in the params array (needed for eventual total calculation)
     *
     * @return updated pickup object
     */
    function preselectHandoverOption(
      pickup,
      courierHandoverOptions,
      courierIndex,
      originIndex,
      options
    ) {
      options = options || {};

      let preferredHandoverOption = courierHandoverOptions[0];

      if (options.preselectAnyHandover) {
        if (options.preferDropoff) {
          for (let index = 0; index < courierHandoverOptions.length; index++) {
            if (courierHandoverOptions[index].category === 'dropoff') {
              // assign
              preferredHandoverOption = courierHandoverOptions[index];
              break;
            }
          }
        }
      } else if (
        courierHandoverOptions.length !== 1 ||
        courierHandoverOptions[0].category !== 'pickup'
      ) {
        return pickup;
      }

      return _pickHandoverOption(pickup, preferredHandoverOption, courierIndex, originIndex);
    }

    function _pickHandoverOption(pickup, courierHandoverOption, courierIndex, originIndex) {
      pickup.handover_option_id = courierHandoverOption.handover_option_id;
      pickup.handoverOptionCategory = courierHandoverOption.category;
      pickup.handoverPickupFee = courierHandoverOption.pickup_fee;

      // Also recalculate if the only pickup is a paid one (has a pickup_fee)
      if (courierHandoverOption.pickup_fee) {
        service.recalculateCourierTotal(
          courierHandoverOption.pickup_fee,
          courierIndex,
          originIndex
        );
      }

      return pickup;
    }

    // -----------------------------------------------
    // 2b. totalCost calculations (request pickup page)
    // -----------------------------------------------

    /**
     * [recalculateCourierTotal]
     * Recalculate the total cost for a specific courier.
     * Used when user select a different handover option within one service
     *
     * @param  {Integer} pickupFee
     * @param  {Integer} index: position of the courier in the data
     */
    service.recalculateCourierTotal = function (pickupFee, courierIndex, originIndex) {
      const courier = service.data.origins[originIndex].couriers[courierIndex];
      courier.totals.pickup_fee = pickupFee;
      courier.applicableCosts = _sanitizeCosts(courier);
      courier.totalCost = _calculateCourierTotalCost(courier.applicableCosts);
    };

    function _calculateDisplayableTotalToTopUp() {
      const total = HelperService.roundUp(
        service.data.totals.total_pay_at_easyship - UserStatusService.availableBalance,
        2
      );

      return total || 0;
    }

    service.dispatchResidentialCoverageCost = function () {
      return calculateAllCourierTotals(service.data.origins);
    };

    service.hasResidentialCoverageToBeApplied = function () {
      const hasAnResidentialFeeNotApplied = !!(
        service.data &&
        service.data.totals &&
        service.data.totals.total_residential_discounted_fee_not_applied
      );

      const showResidentialAddOn = !UserSession.hideResidentialAddOn();

      return hasAnResidentialFeeNotApplied && showResidentialAddOn;
    };

    service.hasInsuranceToBeApplied = function () {
      const hasAnInsuranceFeeNotApplied = !!(
        service.data &&
        service.data.totals &&
        service.data.totals.shipments_count_insured_not_applied
      );

      const showInsuranceAddOn = !UserSession.hideInsuranceAddOn();

      return hasAnInsuranceFeeNotApplied && showInsuranceAddOn;
    };

    service.updateInsuranceCost = function (setAllAsInsured) {
      // 1. find the insurance courier insuranceCourierId
      const insuranceObject = _.find(service.data.origins[0].couriers, {
        courier_id: INSURANCE_OBJECT_ID,
      });
      if (!insuranceObject) return;
      if (!service.data || !service.data.totals) return;

      // Current insurance fees
      const totalInsuranceFee = service.data.totals.total_insurance_fee;
      const shipmentCountInsured = service.data.totals.shipments_count_insured;

      // Not applied fees
      const totalInsuranceFeeNotApplied = service.data.totals.total_insurance_fee_not_applied;
      const shipmentCountInsuredNotApplied =
        service.data.totals.shipments_count_insured_not_applied;

      // 2. Update the value using setAllAsInsured
      // 2a. if value is true -> add the total_insurance_fee_not_applied and shipments_count_insured_not_applied to the current counts
      if (setAllAsInsured && !insuranceObject.insuranceAdded) {
        insuranceObject.shipments_count_insured =
          shipmentCountInsured + shipmentCountInsuredNotApplied;
        insuranceObject.totals.insurance_fee = totalInsuranceFee + totalInsuranceFeeNotApplied;
        insuranceObject.insuranceAdded = true;
      }
      // 2b. if value is false -> remove total_insurance_fee_not_applied and shipments_count_insured_not_applied from the current counts
      else if (!setAllAsInsured && insuranceObject.insuranceAdded) {
        insuranceObject.shipments_count_insured -= shipmentCountInsuredNotApplied;
        insuranceObject.totals.insurance_fee =
          insuranceObject.totals.insurance_fee - totalInsuranceFeeNotApplied;
      }

      // else !setAllAsInsured && !insuranceObject.insuranceAdded -->  do nothing

      // 3. rebuild descriptions
      insuranceObject.courier_display_name = _buildInsuranceDescription(service.data.totals, {
        setAllAsInsured,
        insuranceAdded: insuranceObject.insuranceAdded,
      });

      insuranceObject.courier_name = _buildInsuranceDescription(service.data.totals, {
        setAllAsInsured,
        insuranceAdded: insuranceObject.insuranceAdded,
      });

      // 4. Switch back to false insurance added if it was there
      // needed to stay true to build the descriptions, avoids duplicate the desctiption methods in the previous if statement
      if (!setAllAsInsured && insuranceObject.insuranceAdded)
        insuranceObject.insuranceAdded = false;

      // 5. Find and replace insurance object in list
      const insuranceObjectIndex = _.findIndex(service.data.origins[0].couriers, {
        courier_id: 'insuranceCourierId',
      });

      service.data.origins[0].couriers[insuranceObjectIndex] = insuranceObject;

      // 6. Recalculate grand totals
      calculateAllCourierTotals(service.data.origins);
    };

    service.needsToPayInsurance = function () {
      const totalsToPay = service.data && service.data.totals;

      // Has shipment already insured
      const hasShipmentPreInsured = totalsToPay.shipments_count_insured > 0;

      // Or Chooses to add insurance during the flow
      const userHasIncludedInsuranceInFlow =
        service.payload &&
        service.payload.set_all_as_insured &&
        totalsToPay.shipments_count_insured_not_applied > 0;

      return hasShipmentPreInsured || userHasIncludedInsuranceInFlow;
    };

    /**
     * [calculateAllCourierTotals]
     * Loop through all courier, run calculateCourierTotalCost and append it to their "totals" object
     *
     * @param  {Array} origins
     */
    function calculateAllCourierTotals(origins) {
      return _.each(origins, function (origin) {
        return _.each(origin.couriers, function (courier) {
          courier.applicableCosts = _sanitizeCosts(courier);
          courier.totalCost = _calculateCourierTotalCost(courier.applicableCosts);
          courier.vatCost = _calculateCourierVatCost(courier.totals);

          return courier;
        });
      });
    }

    // Ensure all the lines of totals are to be counted in the grand total
    // Delete lines that we do not want ot count in the total
    function _sanitizeCosts(courier) {
      const totals = angular.copy(courier.totals);

      Object.keys(totals).forEach(function (feeName) {
        if (feeName === 'residential_discounted_fee_not_applied') {
          // If the user as selected set as residential and the coverage has not been added to this cost yet
          if (service.payload.set_all_as_residential) {
            // add to the existing cost or just assign the value
            totals.residential_fee_applied = totals.residential_fee_applied || 0;
            totals.residential_fee_applied += totals.residential_discounted_fee_not_applied;
          }

          // Delete this line of cost to not be counted in the total (it will have been added into residential_fee_applied if needed)
          delete totals[feeName];
        }

        // if we did not add the coverage we do not need the sales tax to be in the list of totals
        if (
          feeName === 'sales_tax_residential_discounted_fee_not_applied' &&
          !service.payload.set_all_as_residential
        ) {
          delete totals[feeName];
        }

        if (feeName === 'residential_discounted_fee_not_applied_shipments_count') {
          delete totals[feeName];
        }

        if (
          feeName === 'discount' &&
          typeof totals[feeName] === 'object' &&
          totals[feeName].amount
        ) {
          totals.appliedDiscount = -totals[feeName].amount;
        }
      });

      return totals;
    }

    /**
     * [calculateCourierTotalCost]
     * Reset and make a sum out of the totals of a single courier
     *
     * @param  {Object} courier
     * @return {Integer} totalCost
     */
    function _calculateCourierTotalCost(totals) {
      return Object.keys(totals).reduce(function (accumulator, feeName) {
        const fee = totals[feeName] || 0;

        if (typeof fee === 'number') {
          accumulator += fee;
        }

        return accumulator;
      }, 0);
    }

    function _calculateCourierVatCost(totals) {
      const salesTax = totals.sales_tax || 0;
      const provincialSalesTax = totals.provincial_sales_tax || 0;
      const residentialCoverageTax = service.payload.set_all_as_residential
        ? totals.sales_tax_residential_discounted_fee_not_applied
        : 0;

      return salesTax + provincialSalesTax + residentialCoverageTax;
    }

    // -----------------------------------------------
    // 2c. Validation when exiting Request Pickup (going to order summary)
    // -----------------------------------------------

    /**
     * [hasEveryInputsNeeded]
     * Make sure the user has filled everything needed in the request pickup step
     * - has addresses selected
     * - handover option radio
     * - date and time dropdowns if needed
     *
     * Fill an errors object in the service to be easily accessible in the components
     *
     * @param  {Object}  payload to be passed to the next step
     * @return {Boolean}
     */
    service.hasEveryInputsNeeded = function (payload) {
      service.errors = {
        residential: false,
        insurance: false,
        options: false,
        pickupInstruction: false,
      };

      if (
        service.hasResidentialCoverageToBeApplied() &&
        typeof payload.set_all_as_residential !== 'boolean'
      ) {
        service.errors.residential = true;
      }

      if (service.hasInsuranceToBeApplied() && typeof payload.set_all_as_insured !== 'boolean') {
        service.errors.insurance = true;
      }

      if (!UserSession.isCompanyEfulfilment()) {
        // Use for loop to be able to use the "break" keyword (avoid looping too long if there are errors)
        for (let j = 0; j < payload.origins.length; j++) {
          // Use for loop to be able to use the "break" keyword (avoid looping too long if there are errors)
          for (let i = 0; i < payload.origins[j].pickups.length; i++) {
            // If the radio is not selected
            if (
              !payload.origins[j].pickups[i].handover_option_id &&
              !payload.schedule_pickup_later
            ) {
              service.errors.options = true;
              break;
            } else {
              // If it is a pickup -> check if the date and time dropdowns are filled
              if (payload.origins[j].pickups[i].handoverOptionCategory === 'pickup') {
                if (
                  !payload.origins[j].pickups[i].time_slot_id ||
                  !payload.origins[j].pickups[i].selected_date
                ) {
                  service.errors.options = true;
                  break;
                }
              }
            }
          }
        }
      }

      payload.origins.forEach(function (payload) {
        if (!payload.errors) return;

        service.errors.pickupInstruction = Object.values(payload.errors).includes(true);
      });

      return !Object.values(service.errors).includes(true);
    };

    service.payOrders = function (payload, token, isReschedulePickup) {
      const confirmEndpoint = isReschedulePickup ? ReschedulePickup.confirm : Checkout.confirm.save;

      // CORE-409: doesn't cover reschedule pickup
      if (isReschedulePickup && payload.payment_method) delete payload.payment_method;

      if ($window.location.pathname === '/basic/order-summary') {
        payload.context = 'Basic Flow';
      }
      if ($window.location.pathname === '/order-summary') {
        payload.context = 'Advanced Flow';
      }
      const _payload = !payload.schedule_pickup_later
        ? payload
        : {
            ...payload,
            origins: (payload?.origins || []).map((d) => ({
              pickup_address_id: d.pickup_address_id,
              sender_address_id: d.sender_address_id,
              shipment_ids: d.pickups.map((c) => c.shipment_ids).flat(),
            })),
          };

      return $q(function (resolve, reject) {
        confirmEndpoint(
          { company_id: UserSession.company.id },
          _AssignConfirmPayload(_payload, token),
          function (data) {
            _confirmSuccessCallback(data);
            resolve(data);
          },
          function (err) {
            _confirmErrorCallback(err);
            reject(err);
          }
        );
      });
    };

    function _AssignConfirmPayload(payload, token) {
      if (token) {
        const payment = setPaymentParams(token);

        _.assign(payload, {
          payment,
        });
      }

      return payload;
    }

    function _confirmSuccessCallback() {
      CompanyService.updateStatus();
    }

    function _confirmErrorCallback(err) {
      if (err && err.data && err.data.company) {
        if (
          UserStatusService.actualBalance !== err.data.company.credit_balance ||
          UserStatusService.availableBalance !== err.data.company.available_credit_balance
        ) {
          UserStatusService.updateActualBalance(err.data.company.credit_balance);
          UserStatusService.updateAvailableBalance(err.data.company.available_credit_balance);
        }

        // TODO: Remove/Update this logic when BE deploy a proper solution https://app.asana.com/0/1199701143096273/1201492813585773/f
        if (err.data.errors.join().includes('Labels already requested:')) {
          $state.go('app.shipments');
        }
      }
    }

    /**
     * setPaymentParams: build payment object with necessary Stripe infos
     * @param {string} token
     * @return payment object
     */
    function setPaymentParams(token) {
      const total = service.data.displayableTotalToTopUp || service.data.totals.total_to_top_up;

      return {
        token,
        total: total.toFixed(2),
        description: `${UserSession.company.currency} ${
          service.data.displayableTotalToTopUp || service.data.totals.total_to_top_up
        }`,
        currency: UserSession.company.currency,
        platform: 'Stripe',
      };
    }

    // -----------------------------------------------
    // 4. Clear (success page)
    // -----------------------------------------------

    service.clearCheckoutDataAndPayload = function () {
      service.data = {};
      service.payload = {};
      service.purePreparedCheckoutData = {};
      service.errors = {
        residential: false,
        insurance: false,
        options: false,
        pickupInstruction: false,
      };
      invalidateCheckoutPrepareQuery().then();
    };

    // -----------------------------------------------
    // Getters
    // -----------------------------------------------

    service.getCourierSelectedHandoverOption = function (originIndex, courierIndex) {
      return service.payload.origins[originIndex].pickups[courierIndex];
    };

    service.getErrorOptionState = function () {
      return !!service.errors.options;
    };
  }

  angular
    .module('app.service.checkoutService', [
      'app.global.user-status',
      'app.factory.Checkout',
      'app.service.CompanyService',
    ])
    .service('CheckoutService', CheckoutService);
})();
