import angular from 'angular';

import './tracking-page';
import './customer-emails';
import './packing-slip';
import './advertising-modal';
import './boxes';
import './insurance';
import notifications from './notifications';
import esTrackingUpdatesModal from './tracking-updates-modal';

angular.module('app.tools', [
  'app.tools.tracking-page',
  'app.tools.customer-emails',
  'app.tools.packing-slip',
  'app.tools.advertising',
  'app.tools.boxes',
  'app.tools.insurance',
  notifications,
  esTrackingUpdatesModal,
]);
