import angular from 'angular';

import { AngularConnectCourierFormCanadaPost } from './AngularConnectCourierFormCanadaPost';

const MODULE_NAME = 'app.couriers.courier-create.connect-courier.form.canada-post';

angular
  .module(MODULE_NAME, [])
  .component('esConnectCourierFormCanadaPost', AngularConnectCourierFormCanadaPost);

export default MODULE_NAME;
