import angular from 'angular';
// eslint-disable-next-line import/no-cycle
import { ICompanyService } from 'typings/company';
import ICompanyGateway from '@client/core/corelogic/ports/company.interface';
import { IUser } from 'typings/user-session';
import { RegisterCourierSubAccountPayload } from '@app/pages/company/types';

// TODO: this file is to be moved to /app
export default class NgCompanyGateway
  implements Omit<ICompanyGateway, 'registerCourierSubAccounts'>
{
  private companyService: ICompanyService;

  constructor() {
    this.companyService = angular
      .element(document.body)
      .injector()
      .get<ICompanyService>('CompanyService');
  }

  // registerCourierSubAccounts in /app/pages/company/gateways.
  registerCourierSubAccounts(
    companyId: string,
    payload: RegisterCourierSubAccountPayload
  ): Promise<any> {
    throw new Error('Method not implemented.');
  }

  getCompanyUsers(): Promise<IUser[]> {
    return new Promise((resolve, reject) => {
      this.companyService
        .getCompanyUsers()
        .then((data: { users: IUser[] }) => resolve(data.users), reject);
    });
  }
}
