import { camelCase as camelCaseFn, snakeCase as snakeCaseFn } from 'change-case/keys';
import { CamelCasedPropertiesDeep, SnakeCasedPropertiesDeep } from 'type-fest';

type ConvertibleType = unknown | ConvertibleType[] | { [key: string]: ConvertibleType };

/** Transfer object from snakeCase to camelCase props */
export const snakeToCamelCase = <T extends ConvertibleType>(obj: T): CamelCasedPropertiesDeep<T> =>
  camelCaseFn(obj, -1) as unknown as CamelCasedPropertiesDeep<T>;

/** Transfer object from camelCase to snakeCase props */
export const camelToSnakeCase = <T extends ConvertibleType>(obj: T): SnakeCasedPropertiesDeep<T> =>
  snakeCaseFn(obj, -1) as unknown as SnakeCasedPropertiesDeep<T>;
